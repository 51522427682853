import { StatusValue } from '../../../../types'
import { CountConfig } from './types'

export const COMPANY_TOTALS = [
   'available',
   'consumableAvailable',
   'loaned',
   'pending',
   'unavailable',
]
export const EMPLOYEE_TOTALS = [
   'available',
   'borrowed',
   'consumableAvailable',
   'pending',
]

export enum TypeValues {
   STANDARD = '1',
   QUANTITY = '2',
   KIT = '3',
   CONSUMABLE = '4',
}

export const ITEM_TOTALS: CountConfig = {
   available: {
      color: '#6CD329',
      icon: 'warehouse-outlined',
      label: 'Available',
      id: StatusValue.Available,
      restricted: [],
   },
   borrowed: {
      color: '#1A4D80',
      icon: 'warehouse-arrow',
      label: 'Borrowed',
      id: StatusValue.Borrowed,
      restricted: [],
   },
   consumableAvailable: {
      color: '#6CD329',
      icon: 'warehouse-outlined',
      label: 'Consumables',
      id: StatusValue.ConsumablesAvailable,
      restricted: ['ConsumablesEnabled'],
   },
   loaned: {
      color: '#FF2222',
      icon: 'warehouse-arrow',
      label: 'Loaned',
      id: StatusValue.Loaned,
      restricted: [],
   },
   pending: {
      color: '#FF821C',
      icon: 'warehouse-outlined',
      label: 'Pending',
      id: StatusValue.Pending,
      restricted: [],
   },
   unavailable: {
      color: '#CFD4DA',
      icon: 'warehouse-outlined',
      label: 'Unavailable',
      id: StatusValue.Unavailable,
      restricted: [],
   },
}
