import React, { memo, useMemo } from 'react'
import { Col, Row } from 'react-grid-system'
import { navigate, Link } from 'gatsby'

import { isEmptyObject } from '../../../../common/utils/functions'
import { useItemTotals, useAPI } from '../../../../hooks'
import { NoResults, StackedProgressBar, StatTile, WidgetBox } from '../..'
import { StatusValue } from '../../../../types'
import { Paths } from '../../../../constants/structure'

import { ItemTotals } from '../../../../hooks/useItemTotals/useItemTotals.type'
import {
   COMPANY_TOTALS,
   EMPLOYEE_TOTALS,
   ITEM_TOTALS,
   TypeValues,
} from './constants'

import * as styles from './styles.module.scss'
import { PATH } from '../../../../constants/global'
import { Type } from '../../../../hooks/useItemTypes/useItemTypes.type'
import { InventoryStatusWidgetProps } from './types'
import { ChildProgressBar } from '../../stackedProgressBar/types'
import { getToolStatusPercentage } from './helpers'

const InventoryStatusWidget = ({ isEmployeeUser }: InventoryStatusWidgetProps) => {
   const request = useAPI()
   const { data, isLoading } = useItemTotals()

   function getItemTotalsAsSegments(counts: ItemTotals, types: string[]) {
      const displayNoTileIncludeTotalOnlyCompany: string[] = ['unavailable']
      const displayNoTileIncludeTotalOnlyEmployee: string[] = ['pending']

      return Object.entries(counts)
         .filter(([key]) => types.includes(key))
         .map(([key, value]) => {
            const displayTile = isEmployeeUser
               ? !displayNoTileIncludeTotalOnlyEmployee.includes(key)
               : !displayNoTileIncludeTotalOnlyCompany.includes(key)
            return {
               color: '',
               label: key,
               num: value,
               displayTile: displayTile,
            }
         })
   }

   const handleButtonClick = async (
      status: StatusValue,
      itemTypes: TypeValues[]
   ) => {
      try {
         const { data: itemTypesList }: { data: Type[] } = await request.get(
            PATH.ITEM_TYPES.LIST
         )
         const options = {
            state: {
               status,
               itemTypes: itemTypesList
                  .filter((type) =>
                     itemTypes.includes(type.Value as TypeValues)
                  )
                  .map((type) => ({ label: type.Text, value: type.Value })),
            },
         }
         return navigate(Paths.ITEMS, options)
      } catch (error) {
         return navigate(Paths.ITEMS)
      }
   }

   const segments = useMemo(
      () =>
         getItemTotalsAsSegments(
            data,
            isEmployeeUser ? EMPLOYEE_TOTALS : COMPANY_TOTALS
         ),
      [data, isEmployeeUser]
   )

   const total = useMemo(
      () => segments.reduce((total, segment) => total + segment.num, 0),
      [segments]
   )

   const progressBars: ChildProgressBar[] = useMemo(() => {
      const availableAmount =
         segments.find((seg) => seg.label === 'available')?.num ?? 0
      const consumableAvailableAmount =
         segments.find((seg) => seg.label === 'consumableAvailable')?.num ?? 0
      const availableTotal = availableAmount + consumableAvailableAmount
      const borrowedTotal =
         segments.find((seg) => seg.label === 'borrowed')?.num ?? 0
      const loanedTotal =
         segments.find((seg) => seg.label === 'loaned')?.num ?? 0
      const pendingTotal =
         segments.find((seg) => seg.label === 'pending')?.num ?? 0
      return [
         {
            variant: 'success',
            percentage: getToolStatusPercentage(total, availableTotal),
         },
         {
            variant: 'info',
            percentage: getToolStatusPercentage(total, borrowedTotal),
         },
         {
            variant: 'warning',
            percentage: getToolStatusPercentage(total, pendingTotal),
         },
         {
            variant: 'danger',
            percentage: getToolStatusPercentage(total, loanedTotal),
         },
      ]
   }, [segments, total])

   const loading = isLoading || isEmptyObject(data)

   return (
      <WidgetBox
         heading="Inventory Status"
         id="Items"
         total={total}
         showSpinner={loading}
      >
         {!loading && <StackedProgressBar bars={progressBars} />}

         <Row gutterWidth={10} className={styles.row}>
            {!loading && total === 0 && (
               <Col xs={12}>
                  <NoResults>
                     {isEmployeeUser ? (
                        <>
                           <div>Your company hasn't added any items yet.</div>
                           <div>
                              Please contact your company admin for more
                              details.
                           </div>
                        </>
                     ) : (
                        <>
                           <div>You haven't added any items yet.</div>
                           <div>
                              <Link to={Paths.ITEMS}>Add your first item</Link>
                           </div>
                        </>
                     )}
                  </NoResults>
               </Col>
            )}
            {!loading &&
               segments?.length > 0 &&
               segments.map(
                  (segment) =>
                     segment.displayTile && (
                        <Col
                           xs={6}
                           key={segment.label}
                           className={styles.column}
                        >
                           <StatTile
                              color={ITEM_TOTALS[segment.label].color}
                              icon={ITEM_TOTALS[segment.label].icon}
                              label={ITEM_TOTALS[segment.label].label}
                              num={segment.num}
                              onClick={() =>
                                 handleButtonClick(
                                    ITEM_TOTALS[segment.label].id,
                                    segment.label === 'consumableAvailable'
                                       ? [TypeValues.CONSUMABLE]
                                       : [
                                            TypeValues.STANDARD,
                                            TypeValues.QUANTITY,
                                            TypeValues.KIT,
                                         ]
                                 )
                              }
                           />
                        </Col>
                     )
               )}
         </Row>
      </WidgetBox>
   )
}

export default memo(InventoryStatusWidget)
