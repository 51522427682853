import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'

import { WidgetBox, WidgetList } from '../..'
import { Paths } from '../../../../constants/structure'
import { useConnectionsTotals } from '../../../../hooks'

const ConnectionsWidget = () => {
   const [listItems, setListItems] = useState<ListItem[]>([])
   const { totals, isLoading, isSuccess } = useConnectionsTotals()

   useEffect(() => {
      if (isSuccess && totals) {
         const items = Object.entries(totals).map(([label, amount]) => ({
            label,
            amount,
         }))
         setListItems(items)
      }
   }, [isSuccess, totals])

   const handleButtonClick = (id?: string) => navigate(Paths.CONNECTIONS)

   return (
      <WidgetBox
         heading="Connections"
         id="Connections"
         onButtonClick={handleButtonClick}
         showSpinner={isLoading}
      >
         <WidgetList items={listItems} onItemClick={handleButtonClick} />
      </WidgetBox>
   )
}

export default ConnectionsWidget
